<template>
  <div class="o-forbidden-word">
    <h2>Het Verboden woord</h2>
    <div class="o-counter">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 961 400"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve"
        xmlns:serif="http://www.serif.com/"
        style="
          fill-rule: evenodd;
          clip-rule: evenodd;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-miterlimit: 1.5;
        "
      >
        <g transform="matrix(1,0,0,1,-83.7884,-235.204)">
          <g transform="matrix(-0.543822,0.941928,-0.981238,-0.566518,1740.78,-142.532)">
            <path
              d="M818.898,316.898L859.122,409.477L778.673,409.477L818.898,316.898Z"
              style="fill: rgb(253, 253, 253); stroke: rgb(253, 253, 253); stroke-width: 2.7px"
            />
          </g>
          <g transform="matrix(1.00854,0,0,1.00751,57.9652,58.0151)">
            <path
              d="M848.685,297.532C848.685,261.336 819.328,231.949 783.169,231.949L126.096,231.949C89.936,231.949 60.58,261.336 60.58,297.532L60.58,463.658C60.58,499.855 89.936,529.241 126.096,529.241L783.169,529.241C819.328,529.241 848.685,499.855 848.685,463.658L848.685,297.532Z"
              style="fill: white"
            />
          </g>
          <g transform="matrix(1.00854,0,0,1.00751,64.1146,58.0151)">
            <path
              d="M848.685,297.532C848.685,261.336 819.328,231.949 783.169,231.949L126.096,231.949C89.936,231.949 60.58,261.336 60.58,297.532L60.58,463.658C60.58,499.855 89.936,529.241 126.096,529.241L783.169,529.241C819.328,529.241 848.685,499.855 848.685,463.658L848.685,297.532Z"
              style="fill: url(#_Linear1)"
            />
          </g>
          <g transform="matrix(2.25821,0,0,2.25821,-746.307,-3353.79)">
            <g transform="matrix(0.52996,0,0,0.52996,523.112,1733.79)">
              <text dominant-baseline="middle" text-anchor="middle" class="counter-text" x="75px" y="-70px">
                {{ count }}
              </text>
            </g>
          </g>
        </g>
        <defs>
          <linearGradient
            id="_Linear1"
            x1="0"
            y1="0"
            x2="1"
            y2="0"
            gradientUnits="userSpaceOnUse"
            gradientTransform="matrix(857.728,0,0,858.61,26.3771,380.595)"
          >
            <stop offset="0" style="stop-color: rgb(228, 228, 228); stop-opacity: 1" />
            <stop offset="1" style="stop-color: rgb(253, 253, 253); stop-opacity: 1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import { socket, api, configuration } from '@dpgradio/creative'

export default {
  name: 'App',
  props: ['id', 'apiKey'],
  data() {
    return {
      buckets: [],
    }
  },
  computed: {
    count() {
      return this.buckets.slice(-2).reduce((partialSum, number) => partialSum + number, 0)
    },
  },
  async mounted() {
    api.setApiKey(this.apiKey)

    const detections = await api.request().get(`/forbidden_words/${this.id}/detections_count`)

    this.listenToSocket()
    this.buckets = Object.values(detections)
    setInterval(this.addBucket, 60000)
  },
  methods: {
    addBucket() {
      this.buckets.push(0)
    },
    addDetection() {
      this.buckets[this.buckets.length - 1] += 1
    },
    listenToSocket() {
      socket
        .connect(configuration.stationId)
        .subscribe({ 'dario:forbidden_word_detection': this.id })
        .on('detection', this.addDetection, { backlog: 1 })
    },
  },
}
</script>

<style lang="scss">
@import '@dpgradio/creative/styles/fonts/qmusic';
@import '@dpgradio/creative/styles/colors/qmusic';

.o-forbidden-word {
  font-family: 'Cervo', sans-serif;
  background-color: rgb(var(--q-red));
  text-transform: uppercase;
  color: white;
  padding: 10px;
  font-weight: 600;
  text-align: center;
  font-size: 20px;

  h2 {
    margin: 6px 0;
  }
}

.o-counter {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  box-sizing: border-box;

  svg {
    max-width: 100%;
  }

  .counter-text {
    font-family: 'Cervo';
    text-align: center;
    font-weight: 600;
    font-size: 288px;
    fill: rgb(236, 54, 38);
  }

  .center {
    display: flex;
    align-content: center;
  }
}
</style>
