import ForbiddenWordCounter from '../components/widgets/ForbiddenWordCounter.vue'
import { configuration } from '@dpgradio/creative'
import { ref } from 'vue'

const brandWidgetConfig = {
  qmusic_be: [
    {
      id: 'forbidden_word',
      name: 'forbidden_word',
      widget: ForbiddenWordCounter,
      props: {
        id: 23,
        apiKey: '12ebe55ada01e1a9',
      },
      startDate: '2025-03-17T06:00:00Z',
      endDate: '2025-03-21T23:00:00Z',
    },
  ],
}

export function useWidgets() {
  const brand = configuration.stationId
  const widgets = brandWidgetConfig[brand] || []
  const currentWidgets = ref([])

  const currentDate = new Date()
  widgets.forEach((widget) => {
    const startDate = new Date(widget.startDate)
    const endDate = new Date(widget.endDate)
    if (startDate <= currentDate && endDate > currentDate) {
      currentWidgets.value.push(widget)
    }

    if (startDate <= currentDate) {
      setTimeout(() => {
        currentWidgets.value.push(widget)
      }, currentDate - startDate)
    }

    if (currentDate <= endDate) {
      setTimeout(() => {
        currentWidgets.value = currentWidgets.value.filter((currentWidget) => currentWidget.id !== widget.id)
      }, endDate - currentDate)
    }
  })

  return currentWidgets
}
